<template>
  <v-card>
    <v-toolbar dark color="blue" class="darken-1">
      <v-toolbar-title>{{ $t("add_house") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="dialogueClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-snackbar
      v-model="snackbar"
      color="error"
      :timeout="callbackResponse.timeout"
      top
    >
      {{ callbackResponse.message }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card-text>
      <v-container>
        <form class="fix-for-nepali-label">
          <v-text-field
            :error-messages="houseNameErrors"
            v-model="editedData.houseName"
            :items="house"
                        dense
                        outlined
            required
          >
            <template v-slot:label>
              {{ $t("house_name") }}
            </template>
          </v-text-field>

          <v-text-field
            :error-messages="houseOwnerErrors"
            v-model="editedData.houseOwner"
            required
                        dense
                        outlined
          >
            <template v-slot:label>
              {{ $t("house_owner_name") }}
            </template></v-text-field
          >

          <v-text-field
            :error-messages="houseNumberErrors"
            v-model="editedData.houseNumber"
            required
                        dense
                        outlined
          >
            <template v-slot:label>
              {{ $t("house_no") }}
            </template>
          </v-text-field>

          <v-text-field
            :error-messages="totalRoomErrors"
            v-model="editedData.totalRoom"
                        type="Number"
                        dense
                        outlined
            required
          >
            <template v-slot:label>
              {{ $t("pax") }}
            </template>
          </v-text-field>

          <!--<v-file-input accept="image/png, image/jpeg, image/bmp"
    error-message="logoerror"
    placeholder="House logo"
    prepend-icon="mdi-camera"
    label="Avatar"
    class="pl-2"
    v-model="editedData.updateLogo"></v-file-input>-->

          <v-file-input
            :error-messages="imageError"
            accept="image/png, image/jpeg, image/bmp,image/jpg"
            placeholder="Image"
            prepend-icon="mdi-camera"
            class="pl-2"
                        dense
                        outlined
            required
            v-model="editedData.image"
          >
            <template v-slot:label>
              {{ $t("house_image") }}
            </template>
          </v-file-input>
        </form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn dark color="red darken-1" @click="dialogueClose">{{
        $t("cancel")
      }}</v-btn>
      <v-btn dark color="blue darken-1" @click="submit">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "createHouseForm",
  props: ["success"],

  computed: {
    houseNameErrors() {
      const errors = [];
      if (!this.$v.editedData.houseName.$dirty) return errors;
      !this.$v.editedData.houseName.required &&
        errors.push("House Name is required.");
      return errors;
    },
    houseOwnerErrors() {
      const errors = [];
      if (!this.$v.editedData.houseOwner.$dirty) return errors;
      !this.$v.editedData.houseOwner.required &&
        errors.push("House Owner Name is required.");
      return errors;
    },
    houseNumberErrors() {
      const errors = [];
      if (!this.$v.editedData.houseNumber.$dirty) return errors;
      !this.$v.editedData.houseNumber.required &&
        errors.push("House Number is required.");
      return errors;
    },

    totalRoomErrors() {
      const errors = [];
      if (!this.$v.editedData.totalRoom.$dirty) return errors;
      !this.$v.editedData.totalRoom.required && errors.push("PAX is required.");
      return errors;
    },
    imageError() {
      const errors = [];
      if (!this.$v.editedData.image.$dirty) return errors;
      !this.$v.editedData.image.required && errors.push("Image is required.");
      return errors;
    },
  },
  data() {
    return {
      callbackResponse: {
        timeout: 6000,
      },
      returnData: [],
      houseType: [],
      roomType: [],
      show: false,
      updated: false,
      snackbar: false,
      editedData: {
        roomCategoryID: "",
        totalRoom: "",
        houseNumber: "",
        houseOwner: "",
        image: "",
      },
      servicesData: {
        data: {},
        message: "",
        color: "",
      },
    };
  },
  validations: {
    editedData: {
      houseName: { required },
      image: { required },
      houseOwner: { required },
      houseNumber: { required },
      totalRoom: { required },
    },
  },
  destroyed() {},
  mounted() {
    this.$forceUpdate();
    this.formData();
  },
  methods: {
    dialogueClose() {
      console.log(this.servicesData);
      this.$emit("formResponse", this.servicesData);
    },
    async formData() {
      //axios.get('Hotel/RoomList').then((response) => {
      //    this.roomType = response.data;
      //})
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var form = new FormData();
        form.append("Image", this.editedData.image);
        form.append("houseName", this.editedData.houseName);
        form.append("totalRoom", this.editedData.totalRoom);
        form.append("houseNumber", this.editedData.houseNumber);
        form.append("houseOwner", this.editedData.houseOwner);

        //RoomCategoryId: parseInt(this.editedData.roomCategoryID),
        //TotalRoom: parseInt(this.editedData.totalRoom),
        //HouseNumber: this.editedData.houseNumber.toString(),
        //HouseOwner: this.editedData.houseOwner.toString()

        axios
          .post("Hotel/InsertIntoHomestayHouseAsync", form)
          .then((response) => {
            if (response.data.success) {
              this.editedData = {};
              this.servicesData.data = response.data;
              this.servicesData.message = "House Created Successfully";
              this.servicesData.color = "success";
              this.dialogueClose();
            } else {
              this.snackbar = true;
              this.callbackResponse.message = response.data.errors[0];
            }
          })
          .catch((response) => {
            this.servicesData.data = response.data;
            this.servicesData.message =
              "Error Adding Service, Please Contact Admin";
            this.servicesData.color = "error";
            this.dialogueClose();
          });
      }
    },
    clear() {
      this.$v.$reset();
      this.editedData.roomCategoryID = "";
    },
  },
};
</script>

<style scoped></style>
